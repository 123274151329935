import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import FacebookIconOutline from "./icons/FacebookIconOutline";
import InstagramIconOutline from "./icons/InstagramIconOutline";
import MailIconOutline from "./icons/MailIconOutline";
import PhoneIconOutline from "./icons/PhoneIconOutline";

const TopBar = () => {
  // const [showTopBar, setShowTopBar] = useState(true);
  const [isHoverInstagram, setIsHoverInstagram] = useState(false);
  const [isHoverFacebook, setIsHoverFacebook] = useState(false);

  const handleMouseEnterInstagram = () => {
    setIsHoverInstagram(true);
  };

  const handleMouseLeaveInstagram = () => {
    setIsHoverInstagram(false);
  };

  const handleMouseEnterFacebook = () => {
    setIsHoverFacebook(true);
  };

  const handleMouseLeaveFacebook = () => {
    setIsHoverFacebook(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      // const shouldShowTopBar = window.scrollY === 0;
      // setShowTopBar(shouldShowTopBar);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className='max-w-screen bg-primary-blue px-2.5 scroll-py-10 py-1  border-b border-solid'>
      <div className='flex justify-between'>
        <div className='flex flex-col md:flex-row gap-2 md:gap-7'>
          <div className='flex flex-row gap-3 items-center'>
            <PhoneIconOutline color='white' />
            <NavLink
              className='text-xs hover:text-orange-hover text-white font-normal font-serif'
              to='tel:(800) 444-4256'
            >
              (800) 444-4256
            </NavLink>
            <div className=' text-orange-hover font-normal text-xs	hidden md:block font-serif'>
              Nationwide
            </div>
          </div>
          <div className='flex flex-row gap-3 items-center'>
            <PhoneIconOutline color='white' />
            <NavLink
              className='text-xs hover:text-orange-hover text-white font-normal font-serif'
              to='tel:(916) 456-4256'
            >
              (916) 456-4256
            </NavLink>
            <div className='text-orange-hover font-normal text-xs	hidden md:block font-serif'>
              Local
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row gap-2 md:gap-7'>
          <div className='flex flex-row gap-3 items-center'>
            <MailIconOutline />
            <NavLink
              className='text-xs text-white font-normal underline hover:text-orange-hover font-serif'
              to='mailto:info@zoescruises.com'
            >
              info@zoescruises.com
            </NavLink>
          </div>
          <div className='flex flex-row gap-3'>
            <NavLink
              to='https://www.facebook.com/zoescruisestours'
              className='text-white transition'
              target='_blank'
              onMouseEnter={handleMouseEnterFacebook}
              onMouseLeave={handleMouseLeaveFacebook}
            >
              <FacebookIconOutline isHover={isHoverFacebook} />
            </NavLink>
            <NavLink
              to='https://www.instagram.com/zoescruises/'
              className='text-white transition'
              target='_blank'
              onMouseEnter={handleMouseEnterInstagram}
              onMouseLeave={handleMouseLeaveInstagram}
            >
              <InstagramIconOutline isHover={isHoverInstagram} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
