import React, { useState, useEffect } from 'react';
import ArrowUpIcon from './icons/ArrowUpIcon';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`flex border rounded-lg bg-primary-blue hover:bg-primary-blue-light hover:cursor-pointer  min-[320px] ${
        isVisible ? 'block' : 'hidden'
      } fixed p-2 z-50 bottom-[20px] right-[50px]`}
      onClick={goTop}
    >
      <ArrowUpIcon color='#F7B227' />
      <p className='text-white hover:text-orange-hover'>UP</p>
    </div>
  );
};

export default ScrollToTop;
