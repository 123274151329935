import { useEffect, useRef } from "react";

const useOnClickOutside = (handler: () => void) => {
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (event: any) => {
      if (
        !domRef.current ||
        domRef.current.contains(event.target) ||
        event.key === "Escape"
      ) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      handler();
    };

    const keyDownListener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();

        handler();
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    document.addEventListener("keydown", keyDownListener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("keydown", keyDownListener);
    };
  }, [domRef, handler]);

  return domRef;
};

export default useOnClickOutside;
