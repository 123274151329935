import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import TopBar from "components/TopBar";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import ErrorPage from "pages/ErrorPage";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N8PCVCKG'
}
TagManager.initialize(tagManagerArgs)
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const Home = React.lazy(() => import("./pages/Home"));
const EscortedDepartures = React.lazy(
  () => import("./pages/EscortedDepartures")
);
const CruiseSpecialist = React.lazy(() => import("./pages/CruiseSpecialist"));
const GlobalVoyages = React.lazy(() => import("./pages/GlobalVoyages"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Place = React.lazy(() => import("./pages/Place"));
// const NewHosted = React.lazy(() => import("./pages/NewHosted"));
const CheckIn = React.lazy(() => import("./pages/CheckIn"));
const Subscribe = React.lazy(() => import("./pages/Subscribe"));
const SpecialistPage = React.lazy(() => import("./pages/SpecialistPage"));

const App = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview'
    });
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <TopBar />
        <Navbar />
        <Routes>
        <Route path="/specialist/:name" element={<SpecialistPage />} />
          <Route path='/subscribe' element={<Subscribe />} />
          <Route path='/home' element={<Home />} />
          <Route path='/escorted-departures' element={<EscortedDepartures />} />
          <Route path='/escorted-departures/:place' element={<Place />} />
          <Route path='/cruise-specialists' element={<CruiseSpecialist />} />
          <Route path='/global-voyages' element={<GlobalVoyages />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/pre-cruise' element={<CheckIn />} />
          <Route path='/' element={<Navigate to='/home' />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
