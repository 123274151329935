import React from "react";

type ButtonProps = {
  size?: "extraSmall" | "small" | "medium" | "large";
  color?: "primary" | "secondary" | "outline" | "outlineDark" | "orange";
  label: string;
  customClass?: string;
  onClick?: () => void;
};

const Button = ({
  size = "medium",
  color = "primary",
  label,
  onClick,
  customClass = "",
}: ButtonProps) => {
  const buttonSizes = {
    extraSmall: "py-1 px-3 text-xs",
    small: "py-2 px-4  text-sm border ",
    medium: "py-2 px-6 text-sm",
    large: "p-3 px-12 text-lg font-semibold",
  };
  const buttonColors = {
    primary:
      "bg-primary-blue hover:bg-primary-blue-light hover:text-orange-hover text-white",
    secondary: "bg-white hover:bg-gray-600 text-black",
    outline:
      "border rounded-lg hover:bg-primary-blue-light hover:text-orange-hover text-white",
    outlineDark:
      "border rounded-lg hover:bg-primary-blue-light hover:text-orange-hover text-black",
    orange:
      "border rounded-lg bg-orange-hover hover:bg-primary-blue-light hover:text-orange-hover text-primary-blue-light",
  };

  const buttonSizeClass = buttonSizes[size];
  const buttonColorClass = buttonColors[color];

  return (
    <button
      className={`${buttonSizeClass} ${buttonColorClass} rounded-md font-loto transition-all duration-300 ${customClass}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
