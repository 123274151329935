import React from "react";

interface FacebookIconProps {
  isHover?: boolean;
}

const FacebookIconOutline = ({ isHover }: FacebookIconProps) => {
  return (
    <svg
      width='26'
      height='29'
      viewBox='0 0 32 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32 14.5C32 6.49186 24.8366 0 16 0C7.16344 0 0 6.49186 0 14.5C0 21.7372 5.85094 27.736 13.5 28.8238V18.6914H9.4375V14.5H13.5V11.3055C13.5 7.6714 15.8888 5.66406 19.5434 5.66406C21.2934 5.66406 23.125 5.94726 23.125 5.94726V9.51562H21.1075C19.12 9.51562 18.5 10.6334 18.5 11.7812V14.5H22.9375L22.2281 18.6914H18.5V28.8238C26.1491 27.736 32 21.7372 32 14.5Z'
        fill={isHover ? "#F7B227" : "white"}
      />
    </svg>
  );
};

export default FacebookIconOutline;
