import React from "react";

const LinesRight = () => {
  return (
    <svg
      className='w-8 h-8'
      aria-hidden='true'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title />

      <g id='Complete'>
        <g id='align-right'>
          <g>
            <polygon
              fill='#ffffff'
              points='19.9 18 11.1 18 11.1 18 19.9 18 19.9 18'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />

            <polygon
              fill='#ffffff'
              points='20 14 4 14 4 14 20 14 20 14'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />

            <polygon
              fill='#ffffff'
              points='19.9 10 11.1 10 11.1 10 19.9 10 19.9 10'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />

            <polygon
              fill='#ffffff'
              points='20 6 4 6 4 6 20 6 20 6'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LinesRight;
