import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Suspense
    fallback={
      <div>
        <div className='flex justify-center items-center'>
          <img
            src='/images/zoes_logo.png'
            className='h-20 w-20 mr-3 bg-[length:500px_500px] md:hidden block cursor-pointer'
            alt='Zoes cruises'
          />
        </div>
      </div>
    }
  >
    <App />
  </Suspense>
);
