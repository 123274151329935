import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { pages } from "utils/common";
import FacebookIcon from "./icons/FacebookIconOutline";
import InstagramIconOutline from "./icons/InstagramIconOutline";
import MailIconOutline from "./icons/MailIconOutline";
import PhoneIconOutline from "./icons/PhoneIconOutline";

const Footer = () => {
  const [isHoverInstagram, setIsHoverInstagram] = useState(false);
  const [isHoverFacebook, setIsHoverFacebook] = useState(false);

  const handleMouseEnterInstagram = () => {
    setIsHoverInstagram(true);
  };

  const handleMouseLeaveInstagram = () => {
    setIsHoverInstagram(false);
  };

  const handleMouseEnterFacebook = () => {
    setIsHoverFacebook(true);
  };

  const handleMouseLeaveFacebook = () => {
    setIsHoverFacebook(false);
  };

  return (
    <footer className=' bg-primary-blue'>
      <div className='mx-auto max-w-screen-xl px-4 pt-8 pb-2 sm:px-6 lg:px-0'>
        <div className='mx-auto max-w-2xl'>
          <p className='text-2xl text-white font-serif font-semibold text-center'>
            Subscribe To Our Mailing List
          </p>
          <p className='text-white font-normal font-sans text-center pt-3'>
            Receive Email Specials!
          </p>
          <div translate='no' className='mailmunch-forms-widget-1127011'></div>
        </div>
        <div className='border border-gray-100 my-4'></div>
        <div className='flex flex-wrap flex-col justify-center md:flex-row md:justify-evenly items-center'>
          <div className='text-center'>
            <p className='text-lg font-semibold font-serif text-orange-hover md:text-left'>
              Contact Us
            </p>
            <nav aria-label='Footer Helpful Nav' className='my-4'>
              <ul className='space-y-4 text-sm text-center'>
                <li className='flex items-center justify-center md:justify-start space-x-4'>
                  <PhoneIconOutline color='white' />
                  <NavLink
                    className='text-sm hover:text-orange-hover text-white font-normal font-serif'
                    to='tel:(916) 456-4256'
                  >
                    (916) 456-4256
                  </NavLink>
                  <div className='text-orange-hover font-normal text-sm	hidden md:block font-serif'>
                    Local
                  </div>
                </li>
                <li className='flex items-center justify-center md:justify-start space-x-4'>
                  <PhoneIconOutline color='white' />
                  <NavLink
                    className='text-sm hover:text-orange-hover text-white font-normal font-serif'
                    to='tel:(800) 444-4256'
                  >
                    (800) 444-4256
                  </NavLink>
                  <div className=' text-orange-hover font-normal text-sm	hidden md:block font-serif'>
                    Nationwide
                  </div>
                </li>
                <li className='flex items-center justify-center md:justify-start space-x-4'>
                  <MailIconOutline />
                  <NavLink
                    className='text-white font-normal font-serif text-sm transition hover:text-orange-hover'
                    to='mailto:info@zoescruises.com'
                  >
                    info@zoescruises.com
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <div className='text-center md:col-span-4 lg:col-span-2'>
            <div className=' w-28 mx-auto my-4 max-w-md flex justify-center'>
              <NavLink to='/home'>
                <img src='/images/zoes_logo.png' className='' alt='Zoes Logo' />
              </NavLink>
            </div>
          </div>
          <div className='text-center font-serif'>
            <p className='text-lg font-semibold font-serif text-orange-hover md:text-left'>
              Follow us{" "}
            </p>
            <div className='my-8 flex items-center justify-center'>
              <NavLink
                to='https://www.facebook.com/zoescruisestours'
                className='text-white transition'
                target='_blank'
                onMouseEnter={handleMouseEnterFacebook}
                onMouseLeave={handleMouseLeaveFacebook}
              >
                <FacebookIcon isHover={isHoverFacebook} />
              </NavLink>
              <span
                className={`pl-4 font-normal text-sm ${
                  isHoverFacebook ? "text-orange-hover" : "text-white "
                }`}
              >
                Facebook
              </span>
            </div>
            <div className='my-8 flex items-center justify-center'>
              <NavLink
                to='https://www.instagram.com/zoescruises/'
                className='text-white transition'
                target='_blank'
                onMouseEnter={handleMouseEnterInstagram}
                onMouseLeave={handleMouseLeaveInstagram}
              >
                <InstagramIconOutline isHover={isHoverInstagram} />
              </NavLink>
              <span
                className={`pl-4 font-normal text-sm ${
                  isHoverInstagram ? "text-orange-hover" : "text-white"
                }`}
              >
                Instagram
              </span>
            </div>
          </div>
        </div>
        <nav aria-label='Footer Nav' className='mt-4'>
          <ul className='flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12'>
            {pages.map((page, id) => {
              return (
                <li key={id}>
                  <NavLink
                    to={page.path}
                    className='text-white text-sm transition hover:text-orange-hover font-serif'
                    aria-current='page'
                  >
                    {page.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className='mt-3 border-t border-white pt-2 dark:border-gray-800'>
          <p className='text-center text-sm leading-relaxed text-gray-400 font-mono'>
            © Zoe’s Cruises & Tours LLC 2023. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
