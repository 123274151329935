interface PagesProp {
  title: string;
  path: string;
  submenuItems?: { title: string; path: string }[];
}

export const pages: PagesProp[] = [
  {
    title: "Home",
    path: "/home",
  },
  {
    title: "Escorted & Hosted Departures",
    path: "/escorted-departures",
  },
  {
    title: " Cruise Specialists",
    path: "/cruise-specialists",
  },

  {
    title: "Global Voyages",
    path: "/global-voyages",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "Pre Cruise Check In",
    path: "/pre-cruise",
  },
];

export const preCruisesCheck = [
  {
    name: "Princess Cruises",
    logo: "/images/companies/princess.png",
    path: "https://book.princess.com/cruisepersonalizer/index.page",
  },
  {
    name: "AmaWaterways",
    logo: "/images/companies/amawaterways.png",
    path: "https://www.amawaterways.com/my-trip/cruise-check-in",
  },
  {
    name: "American Cruise Lines",
    logo: "/images/companies/americancruise.png",
    path: "https://www.americancruiselines.com/account/login",
  },
  {
    name: "Azamara Club Cruises",
    logo: "/images/companies/azamara.png",
    path: "https://www.azamara.com/booked-guests/before-you-board/online-checkin",
  },
  {
    name: "Carnival Cruise Line",
    logo: "/images/companies/carnival.png",
    path: "https://www.carnival.com/profilemanagement/accounts/login?booked=true&returnUrl=%2Fbooked%2Fredirect%3Fgoal%3Dmybooking%26icid%3DCC_my-booking_1862",
  },
  {
    name: "Celebrity Cruises",
    logo: "/images/companies/celebrity.png",
    path: "https://www.celebritycruises.com/account/signin?goto=https:%2F%2Fwww.celebritycruises.com:443%2Fauth%2Foauth2%2Fauthorize%3Fresponse_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520vdsid%26nonce%3DaKFY_AO1I.oV~lybX%26client_id%3Dg9S023t74473ZUk909FN68F0b4N67PSOh92o04vL0BR6537pI2y2h94M6BbU7D6J%26redirect_uri%3Dhttps:%2F%2Fwww.celebritycruises.com%2Faccount%2Fauth%2Fprocess-token%2F%26state%3DoaoL6X1DFLuqeojG9-%2F    ",
  },
  {
    name: "CIE Tours",
    logo: "/images/companies/cie-tours.png",
    path: "https://bookings.cietours.com/res/?_gl=1*1ib7ayi*_ga*NDc1MjEyMDc2LjE2OTI3MzYzMzE.*_ga_7BQ9WBF9B7*MTY5MjczNjMzMS4xLjAuMTY5MjczNjMzMS42MC4wLjA.&_ga=2.171519142.702611364.1692736331-475212076.1692736331",
  },
  {
    name: "Costa Cruises",
    logo: "/images/companies/costa.png",
    path: "https://www.mycosta.com/en/login.html",
  },
  {
    name: "Cunard Line",
    logo: "/images/companies/cunard.png",
    path: "https://my.cunard.com/en-us/mycruise/login?otprrf=1",
  },
  {
    name: "Disney Cruise Line",
    logo: "/images/companies/disney.png",
    path: "https://disneycruise.disney.go.com/en/login?returnUrl=%2Fmy-disney-cruise%2Fmy-reservations%2F&cancelUrl=%2F",
  },
  {
    name: "Emerald Cruises",
    logo: "/images/companies/emerald.png",
    path: "https://tp.scenicglobal.com/default.aspx?Company=EC",
  },
  {
    name: "Globus Journeys Voyages, Inc.",
    logo: "/images/companies/globus.png",
    path: "https://my.globusjourneys.com/Login.aspx?ReturnUrl=%2Fdefault.aspx%3F_ga%3D2.178000289.585738170.1692736474-590377661.1692736474",
  },
  {
    name: "Holland America Line",
    logo: "/images/companies/holland.png",
    path: "https://www.hollandamerica.com/en_US/log-in/sso-log-in.html?login=sf",
  },
  {
    name: "MSC Cruises",
    logo: "/images/companies/msc.png",
    path: "https://www.msccruisesusa.com/manage-booking/web-check-in",
  },
  {
    name: "Norwegian Cruise Line",
    logo: "/images/companies/ncl.png",
    path: "https://www.ncl.com/shorex/login",
  },
  {
    name: "Paul Gauguin Cruises",
    logo: "/images/companies/paul.png",
    path: "https://www.pgcruises.com/#modal_login-customer",
  },
  {
    name: "Pleasant Holidays",
    logo: "/images/companies/pleasant.png",
    path: "https://beta.pleasantholidays.com/booking#/retrieve-booking",
  },
  {
    name: "Royal Caribbean International",
    logo: "/images/companies/royall.png",
    path: "https://www.royalcaribbean.com/booked",
  },
  {
    name: "Scenic Cruises",
    logo: "/images/companies/scenic.png",
    path: "https://tp.scenicglobal.com/",
  },
  {
    name: "Seabourn Cruise Line",
    logo: "/images/companies/seabourn.png",
    path: "https://www.seabourn.com/en/us/my-account/log-in/sso-log-in?login=olci",
  },
  {
    name: "Silversea",
    logo: "/images/companies/silversea.png",
    path: "https://my.silversea.com/Account/LogOn?ReturnUrl=%2f",
  },
  {
    name: "Tauck Tours",
    logo: "/images/companies/tauck.png",
    path: "https://www.tauck.com/login",
  },
  {
    name: "Trafalgar Travel",
    logo: "/images/companies/trafalgar.png",
    path: "https://my.trafalgar.com/login?_gl=1*1aubpdw*_ga*NDMxODc3MzM0LjE2OTA1MjE3Nzc.*_ga_8E71Y9KFYR*MTY5MjczNjc0NS4yLjAuMTY5MjczNjc1MS41NC4wLjA.",
  },

  {
    name: "Uniworld River Cruises",
    logo: "/images/companies/uniworld.png",
    path: "https://my.uniworld.com/login",
  },
  {
    name: "Viking Cruises",
    logo: "/images/companies/viking.png",
    path: "https://www.myvikingjourney.com/home/welcome/",
  },
  {
    name: "Virgin Voyages",
    logo: "/images/companies/virgin.png",
    path: "https://www.virginvoyages.com/accounts/v1/account/signin",
  },
  {
    name: "Windstar Cruises",
    logo: "/images/companies/windstar.png",
    path: "https://passengers.windstarcruises.com/Account/LogOn",
  },
  {
    name: "Natural Habitat Adventures",
    logo: "/images/companies/natural-habitat.png",
    path: "https://myportal.nathab.com/session/new",
  },
];

export const carouselImages = [
  { path: "/images/place/california-costals.png" },
  { path: "/images/carousel/carousel1.png" },
  // { path: "/images/carousel/carousel2.png" },
  { path: "/images/carousel/carousel3.png" },
  { path: "/images/carousel/carousel5.png" },
];

export const testimonials = [
  {
    author: "Elizabeth",
    text: "Thank you again and again for so much help, we really appreciate you, you’re the BEST.",
  },
  {
    author: "Karen & Jim",
    text: "We want to thank you for all the extras you gave us to make our trip even more enjoyable. Our trip was wonderful. Thanks a million for everything.",
  },
  {
    author: "Dianne & Reed",
    text: "We just wanted to thank you for all you do for your clients and we are so happy you are a part of Zoe’s Cruise.",
  },
  {
    author: "Vivian",
    text: "Superb customer service! Appreciate it and that’s why we only travel cruises with you. Thanks again!!",
  },
  {
    author: "Barb and Peter",
    text: "Zoe, you made our cruise! You are terrific. Everyone wants to cruise with you. Thank you for a fantastic time that still makes us smile.",
  },
  {
    author: "Greg & Amparo",
    text: "From the initial contact with you coordinating our booking to responding in a timely fashion, you went that extra mile to provide excellent customer service.",
  },
  {
    author: "Rebecca",
    text: "Thanks, Zoe! We love your cruises — and encourage all our friends to go through you!",
  },
  {
    author: "Allyn",
    text: "Having a wonderful time. Your agency is Superb. Cabin location is great, feel no rocking. Food is very good, staff very accommodating.",
  },
  {
    author: "Joanne",
    text: "We got back Saturday and had a wonderful cruise for mom’s birthday. THANK YOU for all the extra’s.",
  },
  {
    author: "Sheri & Bob",
    text: "Thank you for the generous gifts we received from you aboard the Royal Princess in March. You certainly know how to make a trip extra special.",
  },
];

export const escortedDeparturesPage = [
  {
    mainText:
      "Our Escorted Departures are passenger favorites. If your schedule permits joining one of our Escorted Departures, we know you will find your cruise even more special. We enhance our passengers’ cruise experience with additional gifts, amenities, tours, parties, and the gift of roundtrip transportation to the ship when originating from communities in Central and Northern California.",
  },
];

export interface CruiseSpecialistType {
  id: number;
  profileImage: string;
  name: string;
  role: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
  email?: string;
  secondEmail?: string;
  education?: string;
  travelExperience: string;
  favoriteDestinations: string;
  yearsWithZoe: string;
  communityInvolvement: string;
  specialistImage: string;
  specialistImageBackground?: string;
  specialistImageDescription: string;
  husband?: CruiseSpecialistType;
}

export const globalVoyages = [
  {
    country: "Australia and New Zealand",
    image: "/images/common/australia-and-new-zeland.png",
  },
  {
    country: "Antarctica",
    image: "/images/common/antartica.png",
  },
  {
    country: "Asia",
    image: "/images/common/asia.png",
  },
  {
    country: "Canada",
    image: "/images/common/canada.png",
  },
  {
    country: "Europe",
    image: "/images/common/europe.png",
  },
  {
    country: " South America",
    image: "/images/common/south-america.png",
  },
];

export const cruiseSpecialists: CruiseSpecialistType[] = [
  {
    id: 1,
    profileImage: "/images/profile.jpg",
    name: "Stacey Saephanh",
    role: "Owner, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 9",
    phone2: "(916) 573-2614 direct",
    email: "stacey@zoescruises.com",
    education:
      "California State University, Sacramento Certificate in Meeting and Events Planning; Bachelor’s Degree in Ethnic Studies with concentration in Asian/American Studies",
    travelExperience:
      "Princess: Alaska, Hawaiian Islands, Mexico, Panama Canal, Norway | Viking River: Passage to Eastern Europe River Cruise, Paris and the Heart of Normandy River Cruise Other: Hiking throughout California",
    favoriteDestinations:
      "Alaska (including ziplining in Icy Straight Point), Hawaii, Mexico, the Panama Canal, and waking up to a flock of white swans outside her cabin on the Seine River in France, Pulpit Rock Hike in Stavanger, Norway, Segway Tour in Budapest, Dracula's Castle in Romania",
    yearsWithZoe: "Joined Team in June 2007",
    communityInvolvement:
      "Participates in Half- and Full- Marathons to fund the Leukemia and Lymphoma Society and the Project Fit and Triumph Foundation, an Organization that helps cancer survivors regain strength.",
    specialistImage: "/images/cruise_specialist/stacey.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Kayak Break in Flaam, Norway",
  },
  {
    id: 2,
    profileImage: "/images/cruise_specialist/zoe_mccrea.png",
    name: "Zoe McCrea",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(916) 456-4256 Ext. 4",
    phone2: "",
    email: "zoe@zoescruises.com",
    education:
      "CSUS – BA History, MA Education/Primary; Governor’s Fellowship Recipient - CSUS MA Environmental Planning; Recipient German Marshall Fund Grant for Study of Mined Land Reclamation in Western Europe",
    travelExperience:
      "Worldwide travel within the seven Continents; Extensive travel in both North and South America, Western and Eastern Europe and the Baltic, with time in Australia, Africa, Japan, China, Russia, and the South Pacific.      ",
    favoriteDestinations:
      "Zoe’s passion and interest in both wildlife and the environment have led her to Alaska for 40 summers. Cruises to Alaska began in 1979 with Sitmar Cruise Lines’ Fairsea. Exploring the cultures and the history of South America, as well as Eastern and Western Europe, and the Baltic are also of keen interest.",
    yearsWithZoe:
      "While opening Zoe’s Cruises as part-time “avocation” in 1982, Zoe found the response of passengers to a unique business model focused on providing passengers more benefits and an enriched cruise experience to be too successful! Zoe left what had been her “real world” professionally in Earth Sciences in 1990 to devote full time to Zoe’s Cruises.",
    communityInvolvement:
      "Zoe has had a lifelong involvement in animal welfare through volunteer work in the community, 9 years of service on the Sacramento SPCA Board of Directors, both as Board Member and President, and with the formation of the McCrea 2000 Trust. The McCrea 2000 Trust annually funds grants to a variety of local, regional, national and international Animal Welfare Organizations.",
    specialistImage: "/images/cruise_specialist/zoe_mccrea_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription:
      "Ice trekking near El Chalten in Patagonia, 2007.",
  },
  {
    id: 3,
    profileImage: "/images/cruise_specialist/neil_sherri.png",
    name: "Neil & Sherri Castleman",
    role: "Team Members, Sales /  Cruise Specialists, Escorts for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 25",
    phone2: "(916) 603-3450 direct",
    email: "neil@zoescruises.com",
    secondEmail: "sherri@zoescruises.com",
    education: "",
    travelExperience:
      "Alaska, Hawaii, Mexico, Caribbean, Honduras, Belize, Japan and 46 states of the United States",
    favoriteDestinations: "",
    yearsWithZoe: "Joined Zoe’s Cruises in January 2022",
    communityInvolvement:
      " Promoted American Heart walks and Feed the Hungry programs in two counties.",
    specialistImage: "/images/cruise_specialist/neil_sherri_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "",
  },
  {
    id: 4,
    profileImage: "/images/cruise_specialist/donna.jpeg",
    name: "Donna Crabtree",
    role: "Team Member, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 2",
    phone2: "",
    email: "donna@zoescruises.com",
    education: "Certified Medical Phlebotomist and EKG Technician",
    travelExperience: "Alaska, Canada, Hawaii, Mexico, Panama Canal",
    favoriteDestinations:
      "Donna has traveled extensively in North America and through the Panama Canal, a favorite destination because it offers a journey through history.",
    yearsWithZoe: "Joined Team in January 2006",
    communityInvolvement: "Homeward Bound Golden Retriever Rescue",
    specialistImage: "/images/cruise_specialist/donna_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription:
      "Donna and The Great Mariachis at Zoe’s Cruises Fiesta in Cabo.    ",
  },
  {
    id: 5,
    profileImage: "/images/cruise_specialist/nancy_flint.jpeg",
    name: "Nancy Flint",
    role: "Team Member, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 14",
    phone2: "",
    email: "nancy@zoescruises.com",
    education: "",
    travelExperience:
      "Western and Eastern Europe, New Zealand, Australia, South America, Canada, Mexico, Caribbean, South Pacific, North America, United Kingdom and Spain.",
    favoriteDestinations:
      "Nancy loves the natural beauty of the fjords and glaciers but also enjoys the diversity of cultures and history of Countries around the World. Favorite destinations are New Zealand, Turkey, France, Canada, Alaska, and Australia.",
    yearsWithZoe: "Joined Team in September 2017",
    communityInvolvement:
      "Nancy has served as a Team Captain for American Heart Walks; supports Breast Cancer Awareness and Survivor Programs; and the Lupus Foundation.",
    specialistImage: "/images/cruise_specialist/nancy_flint_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "The Opera House. Sydney, Australia",
  },
  {
    id: 6,
    profileImage: "/images/cruise_specialist/sandy.jpeg",
    name: "Sandy Gunder",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(916) 456-4256 Ext. 19",
    phone2: "(916) 993-5502 direct",
    email: "sandy@zoescruises.com",
    education:
      "University of California San Diego and University of Phoenix – Accounting and Business Administration, Fitness Instruction and Health Management",
    travelExperience:
      "Extensive Worldwide Travel to all seven Continents; experienced all itineraries offered by Princess Cruises, including six World Cruises.",
    favoriteDestinations:
      "Exploring and understanding the history and diverse cultures of the World is Sandy’s passion. The Mediterranean holds a special fascination with Venice being her favorite city. Sandy has found through years of travel there is something to learn and explore in every Port/City she has visited whether for the first time or on numerous visits.",
    yearsWithZoe: "Joined the Team in December 2019",
    communityInvolvement:
      "Coronado Youth Soccer, Coronado High School Parent Support Group",
    specialistImage: "/images/cruise_specialist/sandy_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Victoria Falls, Zambia",
  },
  {
    id: 7,
    profileImage: "/images/cruise_specialist/valerie.jpeg",
    name: "Valerie Jablonka",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(414) 379-1431",
    phone2: "",
    email: "valerie@zoescruises.com",
    education:
      "Bachelor’s of Science Degree; Elementary Education. First-grade Teacher, Substitute Teacher, Tutor",
    travelExperience:
      "Africa, Alaska, the Caribbean, China, Europe, South America, the South Pacific and the United States",
    favoriteDestinations: "China, Africa, South America",
    yearsWithZoe: "Joined Team in 1995",
    communityInvolvement:
      "Junior League Milwaukee, Wisconsin; School Volunteer",
    specialistImage: "/images/cruise_specialist/valerie_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Lisbon, Portugal",
  },
  {
    id: 8,
    profileImage: "/images/cruise_specialist/julie.jpeg",
    name: "Julie Murphy",
    role: "Team Member, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 28",
    phone2: "(916) 836-3699 direct",
    email: "julie@zoescruises.com",
    education: "BA from University of Illinois - Chicago",
    travelExperience:
      "Europe, North and South America (including 49 US States), Asia, Caribbean, Tahiti, India, South Africa, Galapagos",
    favoriteDestinations: "South Africa, Iceland, Japan, Tahiti, Switzerland",
    yearsWithZoe: "Joined Team in 2022",
    communityInvolvement:
      "Local School Involvement, Community Park Cleanup Programs, Volunteer at Animal Shelters",
    specialistImage: "/images/cruise_specialist/julie-murphy.jpg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Alaska",
  },

  {
    id: 9,
    profileImage: "/images/cruise_specialist/susan.png",
    name: "Susan Raulinaitis",
    role: "Team Member, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 3",
    phone2: "",
    email: "susan@zoescruises.com",
    education:
      "California State University, Sacramento: Bachelor’s Degree, Liberal Arts/Education; Elementary Teaching Credential Program; Meeting and Events Planning",
    travelExperience:
      "Destinations throughout the United States, including cruises to Alaska, the Canadian Maritime Provinces and the Caribbean, the Hawaiian Islands, Mexico, Panama Canal, South America",
    favoriteDestinations:
      " Alaska, the Hawaiian Islands, California, especially Santa Cruz and Lake Tahoe. Susan also enjoys the historical significance and tropical beauty of the Panama Canal.",
    yearsWithZoe: "Joined Team in 2007",
    communityInvolvement:
      "Albie Aware Breast Cancer Association, past River Park Mother’s Club, River Park Little League, Babe Ruth and Soccer Clubs and River Park Home Vintners Wine Association",
    specialistImage: "/images/cruise_specialist/susan_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Buchart Gardens. Victoria, British Columbia.",
    husband: {
      id: 1,
      profileImage: "/images/cruise_specialist/jerry.png",
      name: "Jerry Raulinaitis",
      role: "Team Member, Escort for Escorted Departures",
      phone1: "(916) 456-4256 ext.",
      phone2: "",
      email: "",
      education:
        "Sacramento City College: A.A. Degree, General Education; Sacramento State College: Engineering Curriculum; Power Lineman Apprenticeship: Sacramento Municipal Utility District.",
      travelExperience:
        "Destinations throughout the United States, including visiting 40 of the 50 states. Cruise visits to Alaska, Hawaii, Mexico, Nicaragua, Costa Rica, Panama Canal, Columbia, the Caribbean, including Jamaica, Aruba, Grand Cayman and the Bahamas and the Maritime Provinces of Canada/New England.",
      favoriteDestinations:
        "Lake Tahoe, Alaska, Hawaii, Pacific North and California Coast, Utah and Disneyland. Also enjoy the historical and engineering significance and tropical beauty of the Panama Canal.",
      yearsWithZoe: "Joined Team in 2007",
      communityInvolvement:
        "Board of Directors, River Park Neighborhood Association; Art Links Instructor, Sacramento City Unified School District; President, River Park Youth Baseball; Club Manager, River Park Soccer Club; Youth Baseball Manager, 16 years; River Park Home Winemaker’s Association; Power Lineman, Sacramento Municipal Utility District, 36 years.",
      specialistImage: "/images/cruise_specialist/jerry_image.jpeg",
      specialistImageBackground: "",
      specialistImageDescription: "",
    },
  },
  {
    id: 10,
    profileImage: "/images/cruise_specialist/cheri.jpeg",
    name: "Cheri Stephen",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(916) 456-4256 Ext. 12",
    phone2: "(916) 905-4820 direct",
    email: "cheri@zoescruises.com",
    education:
      "Central Washington University, Colorado State University; Studies in Psychology and Music.",
    travelExperience:
      "Western Europe, North America, Eastern and Western Caribbean, and Mexico.",
    favoriteDestinations:
      "Alaska; Zermatt, Switzerland; Salzburg, Germany; and Mexico.",
    yearsWithZoe: "Joined Team in May 2017",
    communityInvolvement:
      "Homeschool Program; Amateur Wine-Making with CAVE (Colorado Association for Viticulture and Enology); Volunteer at Homeward Bound providing support for the homeless in Colorado.",
    specialistImage: "/images/cruise_specialist/cheri_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "A Knight at Castello di Amorosa Winery",
  },
  {
    id: 11,
    profileImage: "/images/cruise_specialist/candy.jpeg",
    name: "Candy Ungerecht",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(916) 456-4256 Ext. 7",
    phone2: "",
    email: "candy@zoescruises.com",
    education:
      "As the District Sales Manager for Princess Cruises, Princess Tours, and Cunard Cruise Line, Candy’s association with Zoe’s Cruises spans almost 30 years.",
    travelExperience:
      "Alaska, Canadian Rockies, Caribbean, Eastern and Western Europe, Hawaii, Mexico, North Africa, North and South America, Panama Canal, Tahiti",
    favoriteDestinations:
      "Alaska, Europe (especially France, Italy and Greece); Tahiti",
    yearsWithZoe: "Joined Team in 2006",
    communityInvolvement:
      "Solano Midnight Sun Foundation, a breast cancer non-profit assisting women with financial aid during their recovery.",
    specialistImage: "/images/cruise_specialist/candy_image.jpeg",
    specialistImageDescription: "Market. Florence, Italy.",
    husband: {
      id: 1,
      profileImage: "/images/cruise_specialist/rick.jpeg",
      name: "Rick Ungerecht",
      role: "Team Member",
      phone1: "(916) 456-4256 ext.",
      phone2: "",
      email: "",
      education:
        "Extensive Management & Computer Training from AT&T, California Real Estate Brokers License, knowledge of Sabre (American Airlines), Polar Online (Princess, Holland America & Cunard Line) NAUI Certified SCUBA Diver",
      travelExperience:
        "Alaska, Canada, Caribbean, Eastern and Western Europe, Hawaii, Mexico, North Africa, North and South America, Panama Canal, Tahiti. All both cruising and land. Rick has traveled to all 50 US States.",
      favoriteDestinations:
        "Alaska, Europe, (Italy is Rick’s favorite European Country) Tahiti, Panama Canal",
      yearsWithZoe: "Joined Team in 2018",
      communityInvolvement:
        "Solano Midnight Sun Foundation, a breast cancer non-profit assisting women with financial aid during their recovery. Former Director of the NSCAR Charitable Foundation (supporting housing for Veterans and the Homeless)",
      specialistImage: "/images/cruise_specialist/rick_image.jpeg",
      specialistImageBackground: "/images/bora-bora.png",
      specialistImageDescription: "",
    },
  },
  {
    id: 12,
    profileImage: "/images/cruise_specialist/lisa.jpeg",
    name: "Lisa Wang",
    role: "Team Member, Sales/ Cruise Specialist, Escort for Escorted Departures",
    phone1: "(916) 456-4256 Ext. 17",
    phone2: "(916) 905-4981 direct",
    email: "lisa@zoescruises.com",
    education:
      "California State University, San Jose (Bachelor’s Degree in Computer Science), Hewlett Packard (IT Certified Professional, System Administrator). Multilingual (English, Taiwanese, and Chinese Mandarin)",
    travelExperience:
      "Extensive Global travel on land and sea to Europe, Asia, Mediterranean, Alaska, Hawaii, Mexico, Caribbean, and Panama Canal",
    favoriteDestinations: "Alaska, Hawaii, Croatia, Japan, England, and Italy",
    yearsWithZoe: "Joined Team in July 2019",
    communityInvolvement:
      "Volunteer for Folsom Zoo Sanctuary, Supporter of Boy Scouts of America",
    specialistImage: "/images/cruise_specialist/lisa_image.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Rafting in Taiwan",
  },
  {
    id: 13,
    profileImage: "/images/cruise_specialist/Inge-profile.jpeg",
    name: "Inge Jacobs",
    role: "Team Member, Sales/ Cruise Specialist",
    phone1: "(916) 456-4256 Ext. 29",
    phone2: "(916) 500-0410 direct",
    email: "inge@zoescruises.com",
    education:
      "M.B.A, Finance: Golden Gate University, (Bachelor's degree, International Business: California State University, Fullerton)",
    travelExperience:
      "I'm a dynamic traveler, united with my deep love for adventure and the thrill of uncovering the hidden treasures our world has to offer. My journey has taken me on numerous road less traveled adventures, seeking the mystery, beauty, and authenticity that can only be found off the beaten path. I've spent a great deal of time exploring many destinations around the globe, and am eternally eager for the next entrancing escapades!",
    favoriteDestinations: "While I cherish every travel experience, some destinations hold a special place in my heart. Mexico, the Caribbean, Malaysia, India, and Thailand have left indelible marks on my soul. Each place has offered unique cultural insights, stunning landscapes, and unforgettable moments that continue to fuel my passion for travel.",
    yearsWithZoe: "I joined Zoe's Cruises & Tours in September 2023 as a Team Member, Sales/ Cruise Specialist.",
    communityInvolvement:
    "Beyond my love for travel, I'm passionate about supporting the state and national parks of California and the United States. These pristine natural wonders are a testament to our planet's beauty and serve as a source of inspiration for many travelers like me. I believe in giving back to these remarkable places, ensuring they continue to thrive for generations to come.",
    specialistImage: "/images/cruise_specialist/Inge-jacobs.jpeg",
    specialistImageBackground: "/images/bora-bora.png",
    specialistImageDescription: "Tulum",
  },
];

export interface EscortedDeparturesProps {
  id: number;
  place: string;
  slug?: string;
  backgroundImage?: string;
  description?: string;
  cruisers: Cruiser[];
}

export interface Cruiser {
  id: number;
  name: string;
  timeOfTravel: string;
  cardImage?: String;
  schedule: string;
  departures: Departure[];
  roundtrip?: string;
  fromTo?: string;
  year?: string;
}

export interface Schedule {
  id: number;
  name: string;
  icon: string;
}

export interface Departure {
  id: number;
  dates: string;
  escortedBy: string;
  cruise: string;
  direction?: string;
  description?: string;
  reverse?: boolean;
  minPassengers?: string;
}

export const escortedDepartures: EscortedDeparturesProps[] = [
  {
    id: 1,
    place: "Alaska",
    slug: "alaska",
    backgroundImage: "/images/carousel/carousel2.png",
    cruisers: [
      {
        id: 1,
        name: "Crown Princess",
        timeOfTravel: "11-Day Inside Passage",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/escortedDepartures/alaska.png",
        schedule:
          "San Francisco, Juneau, Skagway, Icy Strait Point, Sitka, Ketchikan, Haines, Glacier Bay National Park Scenic Cruising, Endicott Arm and Dawes Glacier Scenic Cruising, Tracy Arm Fjord Scenic Cruising",
        departures: [
          {
            id: 1,
            dates: "May 4 – 15, 2024",
            escortedBy: "Stacey & Inge",
            cruise: "CR",
          },
          {
            id: 1,
            dates: "May 15 - 26, 2024",
            escortedBy: "Lisa",
            cruise: "CR",
          },
          {
            id: 2,
            dates: "May 26 – June 6, 2024",
            escortedBy: "Neil & Sherri",
            cruise: "CR",
          },
          {
            id: 3,
            dates: "June 28 – July 9, 2024",
            escortedBy: "Lisa",
            cruise: "CR",
          },

          {
            id: 5,
            dates: "July 9 – 20, 2024",
            escortedBy: "Susan & Jerry",
            cruise: "CR",
          },
          {
            id: 6,
            dates: " July 20 – 31, 2024",
            escortedBy: "Donna",
            cruise: "CR",
          },
          {
            id: 7,
            dates: " July 31 – August 11, 2024",
            escortedBy: "Neil & Sherri",
            cruise: "CR",
          },
          {
            id: 8,
            dates: "August 11 – 22, 2024",
            escortedBy: "Nancy",
            cruise: "CR",
          },
          {
            id: 9,
            dates: "September 13 – 24, 2024",
            escortedBy: "Lisa",
            cruise: "CR",
          },
        ],
      },
      {
        id: 2,
        name: "Sapphire Princess",
        timeOfTravel: "11 Day Denali Explorer",
        roundtrip: "Vancouver (Canada)",
        year: "2024",
        cardImage: "/images/place/alaska-autumn.jpg",
        schedule:
          "Vancouver (Canada), Icy Strait Point, Hubbard Glacier (Scenic Cruising), Sitka, Juneau, Skagway, Endicott Arm and Dawes Glacier (Scenic Cruising), Ketchikan, Vancouver (Canada)",
        departures: [
          {
            id: 1,
            dates: "May 28 – June 8, 2024",
            escortedBy: "Nancy",
            cruise: "Sapphire Princess",
            description: "^based on 50 Guests Booked at Final Payment Due Date",
          },
        ],
      },
      {
        id: 3,
        name: "Royal Princess",
        timeOfTravel: "12 Nights Off the Beaten Path",
        cardImage: "/images/place/alaska-snow.jpg",
        roundtrip: "Vancouver (Canada)",
        year: "2024",
        schedule:
          "Vancouver (Canada), Icy Strait Point, Hubbard Glacier (Scenic Cruising), Sitka, Juneau, Skagway, Endicott Arm and Dawes Glacier (Scenic Cruising), Ketchikan, Vancouver (Canada)",
        departures: [
          {
            id: 2,
            dates: "June 17 – 29, 2024",
            escortedBy: "Stacey & Kao",
            cruise: "Royal Princess",
            description: "^based on 50 Guests Booked at Final Payment Due Date",
          },
        ],
      },
      {
        id: 4,
        name: "Ruby Princess",
        timeOfTravel:
          "22-Day Ultimate Alaska Solstice (with Glacier Bay National Park)",
        roundtrip: "San Francisco",
        year: "2025",
        cardImage: "/images/escortedDepartures/alaska.png",
        schedule:
          "San Francisco, Juneau, Skagway, Icy Strait Point, Sitka, Ketchikan, Haines, Glacier Bay National Park Scenic Cruising, Endicott Arm and Dawes Glacier Scenic Cruising, Tracy Arm Fjord Scenic Cruising",
        departures: [
          {
            id: 12,
            dates: "*June 6 – 28, 2025",
            escortedBy: "Stacey & Kao",
            cruise: "CR",
          },
        ],
      },
      {
        id: 5,
        name: "Ruby Princess",
        timeOfTravel: "11-Day Inside Passage",
        roundtrip: "San Francisco",
        year: "2025",
        cardImage: "/images/carousel/carousel5.png",
        schedule:
          "San Francisco, Juneau, Skagway, Icy Strait Point, Sitka, Ketchikan, Haines, Glacier Bay National Park Scenic Cruising, Endicott Arm and Dawes Glacier Scenic Cruising, Tracy Arm Fjord Scenic Cruising",
        departures: [
          {
            id: 1,
            dates: "*May 4 – 15, 2025",
            escortedBy: "Neil & Sherri",
            cruise: "CR",
          },
          {
            id: 2,
            dates: "*May 15 – 26, 2025",
            escortedBy: "Neil & Sherri",
            cruise: "CR",
          },
          {
            id: 3,
            dates: "*May 26 – June 6, 2025",
            escortedBy: "Lisa",
            cruise: "CR",
          },
          {
            id: 4,
            dates: "*June 28 – July 9, 2025",
            escortedBy: "Julie",
            cruise: "CR",
          },
          {
            id: 5,
            dates: "*July 9 – 20, 2025",
            escortedBy: "Susan & Jerry",
            cruise: "CR",
          },
          {
            id: 6,
            dates: "*July 20 – 31, 2025",
            escortedBy: "Donna",
            cruise: "CR",
          },
          {
            id: 7,
            dates: "*August 11 – 22, 2025",
            escortedBy: "Nancy",
            cruise: "CR",
          },
          {
            id: 8,
            dates: "*September 2 – 13, 2025",
            escortedBy: "Stacey",
            cruise: "CR",
          },
          {
            id: 9,
            dates: "*September 13 – 24, 2025",
            escortedBy: "Lisa",
            cruise: "CR",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    place: "Australia & New Zealand",
    slug: "australia",
    backgroundImage: "/images/carousel/carousel2.png",
    cruisers: [
      {
        id: 1,
        name: "Royal Princess",
        timeOfTravel: "13-Day New Zealand",
        cardImage: "/images/slides/hawaii.png",
        roundtrip: "Sydney, Australia",
        year: "2025",
        schedule:
          "Sydney(Australia), Fiordland National Park (Scenic Cruising), Dunedin (Port Chalmers) (New Zealand), Christchurch (Lyttelton) (New Zealand), Wellington (New Zealand), Tauranga (New Zealand), Auckland, (New Zealand), Bay of Islands, (New Zealand), Sydney(Australia)",
        departures: [
          {
            id: 1,
            dates: "February 15 – 28, 2025",
            escortedBy: "Stacey & Kao",
            cruise: "Royal Princess®",
            description: "^based on 50 Guests Booked by November 17, 2024",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    place: "Caribbean East-West Adventurer",
    slug: "caribbean",
    cruisers: [
      {
        id: 1,
        name: "Sun Princess",
        timeOfTravel: "14-Day Caribbean East/West Adventurer",
        roundtrip: "Fort Lauderdale",
        year: "2024",
        cardImage: "/images/hawaii.png",
        schedule:
          "Ft.Lauderdale (Florida), Roatan (Honduras), Belize City (Belize), Cozumel (Mexico), Princess Cays (Bahamas), Amber Cove (Dominican Republic), Grand Turk (Turks & Caicos), Ft.Lauderdale (Florida)",
        departures: [
          {
            id: 1,
            dates: "November 2 – 16, 2024",
            escortedBy: "Nancy",
            cruise: "Sun Princess®",
            description: "^based on 50 Guests Booked at Final Payment Due Date",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    place: "Circle Caribbean",
    slug: "circle-caribbean",
    cruisers: [
      {
        id: 1,
        name: "Majestic Princess",
        timeOfTravel: "14-Day Circle Caribbean",
        cardImage: "/images/carribean-sea.jpg",
        roundtrip: "Fort Lauderdale",
        year: "2025",
        schedule:
          "Ft.Lauderdale (Florida), St.Maarten, St.Kitts, St.Lucia Barbados, Trinidad and Tobago, Aruba, Curacao, Ft.Lauderdale (Florida)",
        departures: [
          {
            id: 1,
            dates: "February 4 – 18, 2025",
            escortedBy: "Donna & Beau",
            cruise: "Majestic Princess®",
            description: "^based on 50 Guests Booked at Final Payment Due Date",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    place: "Mexico",
    slug: "mexico",
    backgroundImage: "/images/carousel/carousel1.png",
    cruisers: [
      {
        id: 1,
        name: "Crown Princess",
        timeOfTravel: "10-Day Mexican Riviera",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/place/mexican-reviera.png",
        schedule:
          "San Francisco (California), Puerto Vallarta (Mexico), Manzanillo (Mexico), Mazatlan (Mexico), Cabo San Lucas (Mexico), San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "January 23 – February 2, 2024",
            escortedBy: "Donna",
            cruise: "CR",
          },
          {
            id: 2,
            dates: "February 7 – 17, 2024",
            escortedBy: "Nancy",
            cruise: "CR",
          },
          {
            id: 3,
            dates: "March 25 – April 4, 2024",
            escortedBy: "Lisa",
            cruise: "CR",
          },
        ],
      },
      {
        id: 2,
        name: "Ruby Princess",
        timeOfTravel: "10-Day Mexican Riviera",
        roundtrip: "San Francisco",
        cardImage: "/images/place/mexico.png",
        year: "2024",
        schedule:
          "San Francisco (California), Puerto Vallarta (Mexico), Manzanillo (Mexico), Mazatlan (Mexico), Cabo San Lucas (Mexico), San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "October 15 – 26, 2024",
            escortedBy: "Susan, Jerry",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "November 16 – 27, 2024",
            escortedBy: "Lisa",
            cruise: "RU",
          },
        ],
      },
      {
        id: 3,
        name: "Discovery Princess",
        timeOfTravel: "10-Day Mexican Riviera with Total Solar Eclipse",
        roundtrip: "Los Angeles",
        cardImage: "/images/slides/hawaii.png",
        year: "2024",
        schedule:
          "Los Angeles (California) Cabo San Lucas (Mexico),Mazatlan, Mexico, Puerto Vallarta, (Mexico) Manzanillo (Mexico) Los Angeles (California)",
        departures: [
          {
            id: 1,
            dates: "April 3 – 13, 2024",
            escortedBy: "Stacey & Kao",
            cruise: "Discovery Princess®",

            description: "Escorted by Stacey & Kao",
          },
        ],
      },
      {
        id: 4,
        name: "Ruby Princess",
        timeOfTravel: "11-Day Mexican Riviera",
        roundtrip: "San Francisco",
        cardImage: "/images/place/mexico.png",
        year: "2025",
        schedule:
          "San Francisco (California), Puerto Vallarta (Mexico), Manzanillo (Mexico), Mazatlan (Mexico), Cabo San Lucas (Mexico), San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "January 19 – 30, 2025",
            escortedBy: "Nancy",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "March 8 – 19, 2025",
            escortedBy: "Lisa",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "*September 24 – October 5, 2025",
            escortedBy: "Susan & Jerry",
            cruise: "RU",
          },
          {
            id: 4,
            dates: "*October 19 – 30, 2025",
            escortedBy: "Neil & Sherri",
            cruise: "RU",
          },
          {
            id: 5,
            dates: "*November 15 – 26, 2025",
            escortedBy: "Stacey",
            cruise: "RU",
          },
        ],
      },
      {
        id: 5,
        name: "Ruby Princess",
        timeOfTravel: "11-Day Mexican Riviera",
        roundtrip: "San Francisco",
        cardImage: "/images/place/mexico.png",
        year: "2026",
        schedule:
          "San Francisco (California), Puerto Vallarta (Mexico), Manzanillo (Mexico), Mazatlan (Mexico), Cabo San Lucas (Mexico), San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "January 18 – 29, 2026",
            escortedBy: "Nancy",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "March 7 – 18, 2026",
            escortedBy: "Stacey",
            cruise: "RU",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    place: "Grand Mediterranean",
    slug: "mediterran",
    cruisers: [
      {
        id: 1,
        name: "Sun Princess",
        timeOfTravel: "10-Day Grand Mediterranean",
        roundtrip: "",
        fromTo: "Barcelona - Rome",
        cardImage: "/images/place/mediterran.png",
        year: "2024",
        schedule:
          "Barcelona (Spain), Mallorca (Spain), Sicily-Messina (Italy), Athens-Piraeus (Greece) Kusadasi, Turkey (for Ephesus) Santorini (Greece) Naples, Italy (for Capri & Pompeii), Rome-Civitavecchia (Italy)",
        departures: [
          {
            id: 1,
            dates: "February 8 – 18, 2024",
            escortedBy: "Stacey & Kao",
            cruise: "Sun Princess®",
            description: "Independent Transportation",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    place: "Hawaii",
    slug: "hawaii",
    backgroundImage: "/images/carousel/carousel3.png",
    cruisers: [
      {
        id: 1,
        name: "Crown Princess",
        timeOfTravel: "16-Day Hawaiian Islands",
        roundtrip: "San Francisco",
        year: "2023",
        cardImage: "/images/place/hawaii.png",
        schedule:
          "San Francisco (California), Hilo, Honolulu (Oahu), Nawiliwili (Kauai), Lahaina, (Maui), Ensenada, San Francisco (California)",
        departures: [
          {
            id: 1  ,
            dates: "December 22, 2023 – January 7, 2024",
            escortedBy: "Stacey & Kao",
            cruise: "CR",
          },
        ],
      },
      {
        id: 2,
        name: "Crown Princess",
        timeOfTravel: "16-Day Hawaiian Islands",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/place/hawaii.png",
        schedule:
          "San Francisco (California), Hilo, Honolulu (Oahu), Nawiliwili (Kauai), Lahaina, (Maui), Ensenada, San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "January 7 – 23, 2024",
            escortedBy: "Susan & Jerry",
            cruise: "CR",
          },
          {
            id: 2,
            dates: "February 22 – March 9, 2024",
            escortedBy: "Nancy",
            cruise: "CR",
          },
          {
            id: 3,
            dates: "April 4 – 20, 2024",
            escortedBy: "Neil & Sherri",
            cruise: "CR",
          },
        ],
      },
      {
        id: 3,
        name: "Ruby Princess",
        timeOfTravel: "16-Day Hawaiian Islands",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/slides/hawaii.png",
        schedule:
          "San Francisco (California), Hilo, Honolulu (Oahu), Nawiliwili (Kauai), Lahaina, (Maui), Ensenada, San Francisco (California) ",
        departures: [
          {
            id: 1,
            dates: "September 29 – October 15, 2024",
            escortedBy: "Neil & Sherri",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "December 2 – 18, 2024",
            escortedBy: "Bus Only",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "December 18, 2024 – January 3, 2025",
            escortedBy: "Stacey",
            cruise: "RU",
          },
        ],
      },
      {
        id: 4,
        name: "Ruby Princess",
        timeOfTravel: "16-Day Hawaiian Islands",
        roundtrip: "San Francisco",
        year: "2025",
        cardImage: "/images/slides/hawaii.png",
        schedule:
          "San Francisco (California), Hilo, Honolulu (Oahu), Nawiliwili (Kauai), Lahaina, (Maui), Ensenada, San Francisco (California) ",
        departures: [
          {
            id: 1,
            dates: "January 30 – February 15, 2025",
            escortedBy: "Neil & Sherri",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "March 19 – 4, 2025",
            escortedBy: "Nancy",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "October 30 – November 15, 2025",
            escortedBy: "Nancy",
            cruise: "RU",
          },
          {
            id: 4,
            dates: "December 1  – December 17, 2025",
            escortedBy: "Inge",
            cruise: "RU",
          },
          {
            id: 5,
            dates: "December 17  – January 2, 2025",
            escortedBy: "Bus Only",
            cruise: "RU",
          },
        ],
      },
      {
        id: 5,
        name: "Ruby Princess",
        timeOfTravel: "16-Day Hawaiian Islands",
        roundtrip: "San Francisco",
        year: "2026",
        cardImage: "/images/slides/hawaii.png",
        schedule:
          "San Francisco (California), Hilo, Honolulu (Oahu), Nawiliwili (Kauai), Lahaina, (Maui), Ensenada, San Francisco (California) ",
        departures: [
          {
            id: 1,
            dates: "*January 29 – February 14, 2026",
            escortedBy: "Susan & Jerry",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "March 18 – April 3, 2026",
            escortedBy: "Lisa",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "April 3 – 19, 2026",
            escortedBy: "Neil & Sherri",
            cruise: "RU",
          },
         
        ],
      },
    ],
  },
  {
    id: 8,
    place: "Panama Canal",
    slug: "panama-canal",
    backgroundImage: "/images/carousel/carousel4.png",
    description: "Full Transit Historic Lock",
    cruisers: [
      {
        id: 2,
        name: "Sapphire Princess",
        timeOfTravel: "16-Day Panama Canal - Ocean to Ocean",
        year: "2024",
        roundtrip: "San Francisco to Fort Lauderdale",
        cardImage: "/images/cabo-san-lucas.jpg",
        schedule:
          "San Francisco, Cabo San Lucas, San Juan Del Sur, Costa Rica (Puntarenas), Fuerte Amador, Puerto Quetzal, Puerto Vallarta, Panama Canal Full Transit Historic Locks or New Locks (including the Miraflores, Pedro Miguel and Gatun Locks), Cartagena, Ft. Lauderdale",
        departures: [
          {
            id: 1,
            dates: "September 28 – October 14, 2024",
            escortedBy: "Stacey",
            cruise: "SA",
          },
        ],
      },
      {
        id: 3,
        name: "Coral Princess",
        timeOfTravel: "16-Day Panama Canal - Ocean to Ocean",
        year: "2025",
        roundtrip: "Fort Lauderdale to San Francisco",
        cardImage: "/images/slides/panama.png",
        schedule:
          "San Francisco, Cabo San Lucas, San Juan Del Sur, Costa Rica (Puntarenas), Fuerte Amador, Puerto Quetzal, Puerto Vallarta, Panama Canal Full Transit Historic Locks or New Locks (including the Miraflores, Pedro Miguel and Gatun Locks), Cartagena, Ft. Lauderdale",
        departures: [
          {
            id: 1,
            dates: "January 4 – 20, 2025",
            escortedBy: "Lisa",
            cruise: "CR",
            direction: "FLL to SFO",
          },
          {
            id: 2,
            dates: "January 20 – February 5, 2025",
            escortedBy: "Julie",
            cruise: "CR",
            direction: "SFO to FLL",
            reverse: true,
          },
        ],
      },
    ],
  },
  {
    id: 9,
    place: "Pacific North and California Coast",
    slug: "california-costals",
    backgroundImage: "/images/carousel/carousel2.png",
    cruisers: [
      {
        id: 1,
        name: "Crown Princess",
        timeOfTravel: "7-Day Classic Coastal",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/turtle.png",
        schedule:
          "San Francisco (California), Santa Barbara, Long Beach, Ensenada (Mexico), San Diego, San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "April 20 – April 27, 2024",
            escortedBy: "Bus Only",
            cruise: "CR",
          },
        ],
      },
      {
        id: 2,
        name: "Crown Princess",
        timeOfTravel: "7-Day Pacific Northwest Coastal",
        roundtrip: "San Francisco",
        year: "2024",
        cardImage: "/images/hawaii.png",
        schedule:
          "San Francisco (California), Astoria, Seattle, Vancouver (Canada), San Francisco (California)",
        departures: [
          {
            id: 3,
            dates: "April 27 - May 04, 2024",
            escortedBy: "Bus only",
            cruise: "CR",
          },
        ],
      },
      {
        id: 3,
        name: "Ruby Princess",
        timeOfTravel: "7-Day Pacific Northwest Coastal",
        roundtrip: "San Francisco",
        year: "2024 - 2026",
        cardImage: "/images/hawaii.png",
        schedule:
          "San Francisco (California), Astoria, Seattle, Vancouver (Canada), San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "April 27 - May 04, 2025",
            escortedBy: "Bus only",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "October 05 - 12 , 2025",
            escortedBy: "Bus only",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "April 26 - May 03, 2026",
            escortedBy: "Bus only",
            cruise: "RU",
          },
        ],
      },
      {
        id: 4,
        name: "Ruby Princess",
        timeOfTravel: "7-Day Classic Coastal",
        roundtrip: "San Francisco",
        year: "2024 - 2026",
        cardImage: "/images/turtle.png",
        schedule:
          "San Francisco (California), Santa Barbara, Long Beach, Ensenada (Mexico), San Diego, San Francisco (California)",
        departures: [
          {
            id: 1,
            dates: "October 26 - November 2, 2024",
            escortedBy: "Bus Only",
            cruise: "RU",
          },
          {
            id: 2,
            dates: "April 20 - 27, 2025",
            escortedBy: "Bus Only",
            cruise: "RU",
          },
          {
            id: 3,
            dates: "April 12 - 19, 2026",
            escortedBy: "Bus Only",
            cruise: "RU",
          },
        ],
      },
    
    ],
  },
  {
    id: 10,
    place: "Porto",
    slug: "porto",
    cruisers: [
      {
        id: 1,
        name: "AmaDouro",
        timeOfTravel: "7 days",
        roundtrip: "Porto",
        year: "2024",
        cardImage: "/images/place/porto.png",
        schedule:
          "Porto(Portugal), Entre-os-Rios, Regua Pinhao,Vega de Terron, Barca d'Alva, Regua (Porto), Porto(DISEMBARKATION)",
        departures: [
          {
            id: 1,
            dates: "August 31 – September 7, 2024",
            escortedBy: "Stacey & Nancy",
            cruise: "AmaDouro",
            description:
              "Extend your Cruise by adding a 3 Night Pre-Cruise Lisbon Land Package ^based on 25 Guests Booked by April 1, 2024",
          },
        ],
      },
    ],
  },
];
