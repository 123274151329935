import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { pages } from "utils/common";
import LinesRight from "./icons/LinesRight";
import CrossIcon from "./icons/CrossIcon";
import useOnClickOutside from "hooks/useOnClickOutside";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarBgColor, setNavbarBgColor] = useState("");
  const [showSubmenu, setShowSubmenu] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);

  const hamburgerRef = useOnClickOutside(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      const nav = navRef.current;
      if (nav) {
        if (currentScrollPos > 0) {
          nav.style.top = "0";
          setNavbarBgColor(" bg-primary-blue");
        } else {
          nav.style.top = "";
          setNavbarBgColor("");
        }
      }
      const mobileNav = mobileNavRef.current;
      if (mobileNav) {
        if (currentScrollPos > 0) {
          mobileNav.style.top = "0";
          setNavbarBgColor(" bg-primary-blue");
        } else {
          mobileNav.style.top = "";
          setNavbarBgColor("");
        }
      }
    };
  }, []);

  const handleToggle = () => {
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    setIsOpen(!isOpen);
    setShowSubmenu(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(false);
    document.body.style.overflow = "scroll";
  }, [location]);

  const handleNavLinkClick = () => {
    setIsOpen(false);
  };

  const isRouteActive = (routePath: string) => {
    return location.pathname.startsWith(routePath);
  };

  const parentRouteIsActive = (routePath: string) => {
    return location.pathname === routePath;
  };

  return (
    <div>
      <nav
        ref={navRef}
        className={`hidden lg:block fixed w-screen z-50   ${navbarBgColor}`}
      >
        <div className='bg-primary-blue flex items-center justify-evenly p-3 '>
          <div className='h-14 w-14 aspect-aspect-w-1 aspect-aspect-h-1 cursor-pointer'>
            <img
              src='/images/zoes_logo5.png'
              className='w-full h-full object-contain'
              alt='Logo'
              onClick={() => navigate("/")}
              loading='lazy'
            />
          </div>
          <div className='md:flex flex-wrap  items-center justify-center'>
            <ul className='flex flex-col p-4 md:p-0 font-medium rounded-lg items-center md:flex-row gap-x-1'>
              {pages.map((page, id) => {
                return (
                  <li
                    className='text-center relative'
                    key={id}
                    onMouseOver={() =>
                      page.path === "/pre-cruise" && setShowSubmenu(true)
                    }
                    onMouseOut={() =>
                      page.path === "/pre-cruise" && setShowSubmenu(false)
                    }
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <NavLink
                      to={page.path}
                      className={`block py-1 px-4 text-sm text-orange-hover font-serif rounded hover:bg-orange-hover md:hover:bg-transparent md:hover:text-orange-hover  ${
                        isRouteActive(page.path) ||
                        parentRouteIsActive(page.path)
                          ? "text-orange border border-orange-hover"
                          : "text-white"
                      }`}
                      aria-current={
                        page.path === location.pathname ? "page" : undefined
                      }
                    >
                      {page.title}
                    </NavLink>

                    {/* this code can be deleted, because we have new logic, which lead us to CheckIn page */}
                    {/* {showSubmenu && page.submenuItems && (
                      <div className='submenu absolute bg-white'>
                        {page.submenuItems?.map(
                          (submenuItem: any, submenuId: any) => (
                            <NavLink
                              key={submenuId}
                              to={submenuItem.path}
                              className='block py-2 px-4 text-base 2xl:text-lg text-orange-hover font-serif hover:bg-orange-hover md:hover:bg-transparent md:hover:text-orange-hover'
                              aria-current={
                                submenuItem.path === location.pathname
                                  ? "page"
                                  : undefined
                              }
                              onClick={handleNavLinkClick}
                            >
                              {submenuItem.title}
                            </NavLink>
                          )
                        )}
                      </div>
                    )} */}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav
        ref={mobileNavRef}
        className={`min-[320px] block lg:hidden fixed w-screen z-50 top-[71px] md:top-[61px] ${navbarBgColor}`}
      >
        <div
          ref={hamburgerRef}
          className='bg-primary-blue  absolute w-full flex justify-between p-4 items-center'
        >
          <img
            src='/images/zoes_logo.png'
            className='h-20 w-20 mr-gth:50 block cursor-pointer z-20'
            alt='Zoes cruises'
            onClick={() => navigate("/")}
          />
          <button
            onClick={handleToggle}
            type='button'
            className={`inline-flex items-center z-20  ${
              isOpen && "bg-orange-hover"
            }`}
            aria-expanded='false'
          >
            {isOpen ? <CrossIcon /> : <LinesRight />}
          </button>
          {isOpen && (
            <div className='fixed text-light-grey left-0 top-[112px] w-full h-screen bg-primary-blue px-4 py-7 flex-col z-10'>
              <ul className='flex flex-col w-full h-full pt-10 gap-y-8'>
                <img
                  src='/images/zoes_logo.png'
                  className='h-20 w-20 mr-3 bg-[length:500px_500px] hidden md:block cursor-pointer'
                  alt='Flowbite Logo'
                  onClick={() => navigate("/")}
                />
                {pages.map((page, id) => {
                  return (
                    <li
                      className={`text-center ${
                        page.path === "/pre-cruise" &&
                        "flex justify-center items-center"
                      }`}
                      key={id}
                      onMouseOver={() =>
                        page.path === "/pre-cruise" && setShowSubmenu(true)
                      }
                      onMouseOut={() =>
                        page.path === "/pre-cruise" && setShowSubmenu(false)
                      }
                    >
                      <NavLink
                        to={page.path}
                        className={`block py-2 pl-3 pr-4 text-base 2xl:text-lg text-orange-hover font-serif rounded hover:bg-orange-hover md:hover:bg-transparent md:hover:text-orange-hover  ${
                          page.path === location.pathname
                            ? "text-orange border-2 border-orange-hover"
                            : "text-white"
                        } ${
                          page.path === location.pathname && "hover:text-white"
                        }`}
                        aria-current={
                          page.path === location.pathname ? "page" : undefined
                        }
                        onClick={handleNavLinkClick}
                      >
                        {page.title}
                      </NavLink>

                      {showSubmenu && page.submenuItems && (
                        <div className='submenu absolute bg-white mt-32'>
                          {page.submenuItems?.map(
                            (submenuItem: any, submenuId: any) => (
                              <NavLink
                                key={submenuId}
                                to={submenuItem.path}
                                className='block py-2 pl-3 pr-4 text-base 2xl:text-lg text-orange-hover font-serif rounded hover:bg-orange-hover md:hover:bg-transparent md:hover:text-orange-hover w-[50vw]'
                                aria-current={
                                  submenuItem.path === location.pathname
                                    ? "page"
                                    : undefined
                                }
                                onClick={handleNavLinkClick}
                              >
                                {submenuItem.title}
                              </NavLink>
                            )
                          )}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
